<template>
  <div>
    <GmapMap
      ref="mapRef"
      :center="center"
      :zoom="zoom ? zoom : '15'"
      style="width: 100%; height: calc(100vh)"
    >
      <div :key="index2" v-for="(m2, index2) in wells_all">
        <GmapCircle
          ref="circleRef"
          :key="index"
          v-for="(m, index) in cercles"
          :center="m2.center"
          :radius="m.rad"
          :visible="true"
          :options="m.option"
        ></GmapCircle>
      </div>
    </GmapMap>
  </div>
</template>

<script>
import WELLSMAP from "../graphql/Well/WELLSMAP.gql";

function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
export default {
  name: "Map",
  props: {
    search: String,
    FilterScope: String,
    CatScope: Array,
    lifecycle: [],
    list_key: Boolean,
  },
  data() {
    return {
      center: { lat: 10.0, lng: 10.0 },
      zoom: 10,
      wells: [],
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
      wells_srch: [],
      wells_all: [],
      filtre: "",
      paths: [
        { lat: 25.774, lng: -80.19 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
      ],
      cercles: [
        {
          option: {
            strokeColor: "red",
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: "#FF0000",
            fillOpacity: 0,
          },
          rad: 200,
        },
        {
          option: {
            strokeColor: "blue",
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: "#FF0000",
            fillOpacity: 0,
          },
          rad: 300,
        },
        {
          option: {
            strokeColor: "green",
            strokeOpacity: 0.8,
            strokeWeight: 4,
            fillColor: "#FF0000",
            fillOpacity: 0,
          },
          rad: 400,
        },
      ],
    };
  },
  watch: {
    search: debounce(function () {
      this.expire = this.search ? 3 : 1;
      this.searchmarkers();
    }, 1000),

    list_key: {
      handler() {
        // this.searchmarkers();
        this.reloaddata();
      },
    },
  },
  mounted() {
    //this.$el.addEventListener("touchmove", { passive: true });
    this.locateGeoLocation();
    this.reloaddata();
    // this.wells_flt = this.wells.filter(
    //   (x) => this.lifecycle.indexOf(x.statut_id.toString()) !== -1
    // );
  },

  methods: {
    locateGeoLocation: function () {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    openForm(wellid) {
      // if (!this.map) return;
      this.well = this.wells.filter((w) => w.id == wellid)[0];
      this.$emit("open", this.well);
    },
    async searchmarkers() {
      if (this.search == "")
        this.wells_flt = this.wells.filter(
          (x) => this.lifecycle.indexOf(x.lifecycle.toString()) !== -1
        );
      else {
        this.wells_flt = this.wells.filter(
          (x) =>
            x.wellname.includes(this.search) &&
            this.lifecycle.indexOf(x.lifecycle.toString()) !== -1
        );
        if (this.wells_flt.length >= 0) {
          this.center.lat = this.wells_flt[0].lat ? this.wells_flt[0].lat : 0;
          this.center.lng = this.wells_flt[0].lng ? this.wells_flt[0].lng : 0;

          this.$refs.mapRef.$mapPromise.then((map) => {
            map.panTo(this.center);
          });
        }
      }
    },
    async reloaddata() {
      await this.$apollo
        .query({
          query: WELLSMAP,
          variables: {
            CatScope: 2,
            FilterScope: this.FilterScope,
            Type: this.lifecycle,
          },
        })
        .then((data) => {
          this.snackbar = false;
          if (this.FilterScope == "" || this.FilterScope == null)
            this.wells_srch = [];
          else this.wells_srch = data["data"]["wellsmap"];
          this.wells_all = data["data"]["wellsmap"];
          this.wells_all.forEach((element) => {
            element.center = { lat: element.lat, lng: element.lng };
          });
          // this.searchmarkers();
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
    },
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
  height: 100vh;
  width: 100%;
  min-height: 100%;
  max-height: none;
}
</style>
